import React from 'react'
import './Buttons.css'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import t from '../../i18n/translations/translations'
import { removeCookie } from '../../utils/cookiesFunctions'
import { getCookie } from '../../utils/cookiesFunctions'

export function UserProfileButton({ lang }) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleNavigationUser = () => {

        const userId = getCookie('idUser');

        let slug = window.location.pathname
        let lang = slug.split('/')[1]
        if (lang === 'es') {
            slug = 'preferencias-usuario/' + userId
        } else {
            slug = 'erabiltzailearen-lehentasunak/' + userId
        }
        window.location.href =
            window.location.protocol +
            '//' +
            window.location.hostname +
            '/' +
            lang +
            '/' +
            slug
    }

    const handleNavigationBookings = () => {
        let slug = window.location.pathname
        let lang = slug.split('/')[1]
        if (lang === 'es') {
            slug = 'reservas'
        } else {
            slug = 'erreserbak'
        }
        window.location.href =
            window.location.protocol +
            '//' +
            window.location.hostname +
            '/' +
            lang +
            '/' +
            slug
    }

    const handleNavigationLogOut = () => {
        removeCookie()
        window.location.href = '/'
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <button
                aria-label="user-menu"
                className="button-no-style"
                id="userProfileButton"
                onClick={handleClick}
                tabIndex="0"
                title="Menu"
                type="button"
            >
                <i title="search" className="fa fa-user" aria-hidden="true" />
            </button>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleNavigationUser}>
                    {t.profile[lang]}
                </MenuItem>
                <MenuItem onClick={handleNavigationBookings}>
                    {t.booking[lang]}
                </MenuItem>
                <MenuItem onClick={handleNavigationLogOut}>
                    {t.log_out[lang]}
                </MenuItem>
            </Menu>
        </>
    )
}
