import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AdvancedMarker, Map } from '@vis.gl/react-google-maps'
import $ from 'jquery'
import Moment from 'moment'
import 'moment/locale/es'
import React, { useEffect, useState } from 'react'
import ImageUploader from 'react-images-upload'
import { useDispatch, useSelector } from 'react-redux'
import CalendarioInput from '../../assets/svg/CalendarioInput'
import CheckFormSended from '../../assets/svg/Check-circle'
import CheckFormNoSended from '../../assets/svg/times-circle'
import PageRow from '../../atoms/PageRow/PageRow'
import { SUCCESS_CODE } from '../../constants'
import {
    EMAIL_REGEX,
    ONLY_LETTERS_AND_NUMBERS_REGEX,
} from '../../constants/regex'
import useSetIframeTitle from '../../hooks/useSetIframeTitle'
import t from '../../i18n/translations/translations'
import {
    setDefaultCoordenates as setDefaultCoordenatesAction,
    setSelectedCategories as setSelectedCategoriesAction,
} from '../../store/actions'
import InputField from './FormComponents/InputField'
import './NewIncidence.css'

function NewIncidence({ isMobile, isTablet, match, translations }) {
    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)
    const [latitudeIncidence, setLatitudeIncidence] = useState(0)
    const [longitudeIncidence, setLongitudeIncidence] = useState(0)
    const [titulo, setTitulo] = useState('')
    const [bs64Pictures, setBs64Pictures] = useState([])
    const [picturesSended, setPicturesSended] = useState(true)
    const [descripcion, setDescripcion] = useState('')
    const [categorias, setCategorias] = useState('')
    const [masInfo, setMasInfo] = useState('')
    const [zoom, setZoom] = useState(17)
    const [email, setEmail] = useState('')
    const [isEmailValid, setIsEmailValid] = useState(true)
    const [isCategoriesValid, setIsCategoriesValid] = useState(true)
    const [isMasInfoValid, setIsMasInfoValid] = useState(true)
    const [isDescriptionValid, setIsDescriptionValid] = useState(true)
    const [isTitleValid, setIsTitleValid] = useState(true)
    const [isFormValid, setIsFormValid] = useState(true)

    const { lang } = match

    useSetIframeTitle(t.new_incidence_map[lang])

    const dispatch = useDispatch()

    const setSelectedCategories = (payload) =>
        dispatch(setSelectedCategoriesAction(payload))
    const setDefaultCoordenates = (payload) =>
        dispatch(setDefaultCoordenatesAction(payload))

    const { incidencia, categories, plans } = useSelector((state) => ({
        incidencia: state.incidencia,
        categories: state.categories?.undefined,
        plans: state.plans?.undefined,
    }))

    useEffect(() => {
        setTimeout(
            $('.chooseFileButton').on('click', () => {
                $('.fileUploader').css('background', 'transparent')
                $('.chooseFileButton').text(t.image_upload_title[lang])
            }),
            100000
        )

        const items = {
            lang: incidencia.lang,
            domain: incidencia.domain,
        }

        setSelectedCategories(items)
        setDefaultCoordenates(items)
    }, [incidencia, lang])

    useEffect(() => {
        if (plans !== undefined) {
            const { latitude: latitudePlans, longitude: longitudePlans } =
                plans.data[0]

            setLatitude(parseFloat(latitudePlans))
            setLongitude(parseFloat(longitudePlans))
            setLatitudeIncidence(0)
            setLongitudeIncidence(0)
            setPicturesSended(true)
        }
    }, [plans])

    const handleChangeZoom = (newZoom) => {
        setZoom(newZoom)
    }

    const handleClickLocation = (event) => {
        const { lat, lng } = event.detail.latLng
        document.getElementById('location-warning').style.display = "none";
        setLatitudeIncidence(lat)
        setLongitudeIncidence(lng)
    }

    const handleReaderLoaded = (readerEvt) => {
        const binaryString = readerEvt.target.result
        const imgBs64 = btoa(binaryString)

        setBs64Pictures(bs64Pictures.concat(imgBs64))
    }

    const handleChange = (event) => {
        const { name, value } = event.target

        switch (name) {
            case 'categorias':
                setCategorias(value)
                setIsCategoriesValid(!!value)
                break
            case 'descripcion':
                setDescripcion(value)
                setIsDescriptionValid(
                    value ? ONLY_LETTERS_AND_NUMBERS_REGEX.test(value) : true
                )
                break
            case 'email':
                setEmail(value)
                setIsEmailValid(value ? EMAIL_REGEX.test(value) : true)
                break
            case 'masInfo':
                setMasInfo(value)
                setIsMasInfoValid(ONLY_LETTERS_AND_NUMBERS_REGEX.test(value))
                break
            case 'titulo':
                setTitulo(value)
                setIsTitleValid(ONLY_LETTERS_AND_NUMBERS_REGEX.test(value))
                break
            default:
                break
        }
    }

    const onDrop = (picturesDropped) => {
        setBs64Pictures([])

        picturesDropped.forEach((picture) => {
            if (picture) {
                const reader = new FileReader()
                reader.onload = handleReaderLoaded
                reader.readAsBinaryString(picture)
            }
        })
    }

    const formularioExitoFracaso = (objeto, color, animation) => {
        $(`.${objeto}`).css({
            'background-color': color,
            height: '60px',
            width: '100%',
            transition: 'color 0.8s linear 0.2s',
            'animation-name': animation,
            'animation-duration': '3s',
            alignItems: 'center',
        })
        $(`.${objeto}`).css({ visibility: 'visible' })
        $('.form').click(() => {
            $(`.{objeto}`).css({
                visibility: 'hidden',
                height: '0px',
                width: '0%',
            })
            $('.fileUploader').css('background', '')
        })
    }

    useEffect(() => {
        if (
            !isEmailValid ||
            !isCategoriesValid ||
            !isDescriptionValid ||
            !isMasInfoValid ||
            !isTitleValid
        ) {
            setIsFormValid(false)
            return
        }

        setIsFormValid(true)
    }, [
        isEmailValid,
        isCategoriesValid,
        isDescriptionValid,
        isMasInfoValid,
        isTitleValid,
    ])

    const handleSubmit = (event) => {
        event.preventDefault()

        if (isFormValid) {
            const root = process.env.REACT_APP_API_URL

            const url = `${root}kalezaindu/incidences/?_lang=${incidencia.lang}&_dominio=${incidencia.domain}`

            const response = fetch(url, {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    aboutAdress: masInfo,
                    category: parseInt(categorias, 10),
                    description: descripcion,
                    email,
                    longitude: parseFloat(longitude),
                    latitude: parseFloat(latitude),
                    title: titulo,
                    images: bs64Pictures,
                }),
            })

            response.then((value) => {
                if (value.status === 204 || value.status === SUCCESS_CODE) {
                    setBs64Pictures([])
                    formularioExitoFracaso(
                        'formulario_enviado',
                        'rgba(16, 165, 16, 0.74',
                        'anim2'
                    )

                    setPicturesSended(false)
                    $('.fileUploader').css('background', '')
                }
                if (value.status === 500) {
                    formularioExitoFracaso(
                        'formulario_no_enviado',
                        'rgba(247, 54, 54, 0.788)',
                        'anim'
                    )
                }
            })
        }
    }

    let type = null
    if (isMobile) {
        type = 'Mobile'
    } else if (isTablet) {
        type = 'Tablet'
    } else {
        type = 'null'
    }

    const DateNow = new Date()

    return (
        <PageRow className="ni-container">
            {(() => {
                switch (type) {
                    case 'Mobile':
                        return (
                            <div className="newIncidence">
                                <div className="newindicenciaDetail_mapContainer">
                                    <Map
                                        defaultCenter={{
                                            lat: latitude,
                                            lng: longitude,
                                        }}
                                        defaultZoom={zoom}
                                        mapId="new-incidence"
                                        onClick={handleClickLocation}
                                        onZoomChanged={handleChangeZoom}
                                        style={{
                                            height: '700px',
                                        }}
                                    >
                                        <AdvancedMarker
                                            position={{
                                                lat: latitude,
                                                lng: longitude,
                                            }}
                                        />
                                    </Map>
                                </div>
                                <div className="infobox_card_all">
                                    <div className="tablet_incidenciaDetail-infobox_header">
                                        <h1 className="infobox_header-title">
                                            {
                                                translations.KALEZAINDU
                                                    .KALEZAINDU_NUEVA_INCIDENCIA
                                            }
                                        </h1>
                                        <div className="infobox_header-status_date">
                                            <CalendarioInput />
                                            <p>
                                                {Moment(DateNow).format(
                                                    'D MMM YYYY'
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="formulario_enviado formularioVisible">
                                            <CheckFormSended />
                                            <p>
                                                {
                                                    translations.KALEZAINDU
                                                        .KALEZAINDU_FORMULARIO_ENVIADO
                                                }
                                            </p>
                                        </div>
                                        <div className="formulario_no_enviado formularioNoEnviadoVisible">
                                            <CheckFormNoSended />
                                            <p>
                                                {
                                                    translations.KALEZAINDU
                                                        .KALEZAINDU_FORMULARIO_NO_ENVIADO
                                                }
                                            </p>
                                        </div>

                                        <div className="newinfobox_status-description">
                                            <InputField
                                                ariaRequired
                                                isValid={isTitleValid}
                                                lang={lang}
                                                name="titulo"
                                                onChange={handleChange}
                                                placeholder={t.title[lang]}
                                                required
                                                title={
                                                    translations.KALEZAINDU
                                                        .KALEZAINDU_FORMULARIO_TITULO
                                                }
                                                type="text"
                                                value={titulo}
                                            />
                                            <InputField
                                                isValid={isDescriptionValid}
                                                lang={lang}
                                                name="descripcion"
                                                value={descripcion}
                                                onChange={handleChange}
                                                title={
                                                    translations.KALEZAINDU
                                                        .KALEZAINDU_FORMULARIO_DESCRIPCION
                                                }
                                                type="textarea"
                                                placeholder={
                                                    translations.KALEZAINDU
                                                        .KALEZAINDU_FORMULARIO_DESCRIPCION_PLACEHOLDER
                                                }
                                            />
                                            <InputField
                                                ariaRequired
                                                isValid={isCategoriesValid}
                                                name="categorias"
                                                value={categorias}
                                                onChange={handleChange}
                                                required
                                                title={
                                                    translations.KALEZAINDU
                                                        .KALEZAINDU_FORMULARIO_DESCRIPCION
                                                }
                                                type="Categoria"
                                                categoria={categories}
                                                lang={incidencia.lang}
                                            />
                                            <InputField
                                                isValid={isMasInfoValid}
                                                lang={lang}
                                                name="masInfo"
                                                value={masInfo}
                                                onChange={handleChange}
                                                placeholder={t.more_info[lang]}
                                                title={
                                                    translations.KALEZAINDU
                                                        .KALEZAINDU_FORMULARIO_MAS_INFORMACION
                                                }
                                                type="text"
                                            />
                                            <ImageUploader
                                                buttonText={
                                                    t.image_upload_title[lang]
                                                }
                                                onChange={onDrop}
                                                imgExtension={[
                                                    '.jpg',
                                                    '.gif',
                                                    '.png',
                                                    '.jpeg',
                                                ]}
                                                maxFileSize={10485760}
                                                maxCount={6}
                                                label={`${t.image_size[lang]}\n${t.image_quantity[lang]}\n${t.image_types[lang]}`}
                                                singleImage={false}
                                                withPreview={picturesSended}
                                                withIcon={false}
                                                buttonStyles={{
                                                    color: '#FFFFFF',
                                                    'background-color':
                                                        '838B99',
                                                    height: '200px',
                                                    width: '210px',
                                                }}
                                                labelStyles={{
                                                    whiteSpace: 'pre-wrap',
                                                }}
                                            />
                                        </div>
                                        <div className="send_box">
                                            <div className="captcha">
                                                {/* <ReCAPTCHA
                            sitekey="xqiopre"
                            onChange={onChange}
                            size="normal"
                          /> */}
                                            </div>
                                            <div>
                                                <input
                                                    aria-label={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_SUBMIT_BUTTON
                                                    }
                                                    className="incidencias-reportBoton"
                                                    type="submit"
                                                    value={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_SUBMIT_BUTTON
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )
                    case 'Tablet':
                        return (
                            <div className="newIncidence">
                                <div className="newindicenciaDetail_mapContainer">
                                    <Map
                                        defaultCenter={{
                                            lat: latitude,
                                            lng: longitude,
                                        }}
                                        defaultZoom={zoom}
                                        mapId="new-incidence"
                                        onClick={handleClickLocation}
                                        onZoomChanged={handleChangeZoom}
                                        style={{
                                            height: '700px',
                                        }}
                                    >
                                        <AdvancedMarker
                                            position={{
                                                lat: latitudeIncidence,
                                                lng: longitudeIncidence,
                                            }}
                                        />
                                    </Map>
                                </div>
                                <div className="new_incidenceindicenciaDetail-infobox">
                                    <div className="incidenciaDetail-infobox_header">
                                        <h1 className="infobox_header-title">
                                            {
                                                translations.KALEZAINDU
                                                    .KALEZAINDU_NUEVA_INCIDENCIA
                                            }
                                        </h1>
                                        <div className="infobox_header-status">
                                            <div className="infobox_header-status_date">
                                                <CalendarioInput />
                                                <p>
                                                    {Moment(DateNow).format(
                                                        'D MMM YYYY'
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="formulario_enviado formularioVisible">
                                            <CheckFormSended />
                                            <p>
                                                {
                                                    translations.KALEZAINDU
                                                        .KALEZAINDU_FORMULARIO_ENVIADO
                                                }
                                            </p>
                                        </div>
                                        <div className="formulario_no_enviado formularioNoEnviadoVisible">
                                            <CheckFormNoSended />
                                            <p>
                                                {
                                                    translations.KALEZAINDU
                                                        .KALEZAINDU_FORMULARIO_NO_ENVIADO
                                                }
                                            </p>
                                        </div>
                                        <div className="newinfobox_status-description">
                                            <div className="leftcolumn">
                                                <InputField
                                                    ariaRequired
                                                    isValid={isTitleValid}
                                                    lang={lang}
                                                    name="titulo"
                                                    value={titulo}
                                                    onChange={handleChange}
                                                    placeholder={t.title[lang]}
                                                    required
                                                    title={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_FORMULARIO_TITULO
                                                    }
                                                    type="text"
                                                />
                                                <ImageUploader
                                                    buttonText={
                                                        t.image_upload_title[
                                                            lang
                                                        ]
                                                    }
                                                    onChange={onDrop}
                                                    imgExtension={[
                                                        '.jpg',
                                                        '.gif',
                                                        '.png',
                                                        '.jpeg',
                                                    ]}
                                                    maxFileSize={10485760}
                                                    maxCount={6}
                                                    label={`${t.image_size[lang]}\n${t.image_quantity[lang]}\n${t.image_types[lang]}`}
                                                    singleImage={false}
                                                    withPreview={picturesSended}
                                                    withIcon={false}
                                                    buttonStyles={{
                                                        color: '#FFFFFF',
                                                        'background-color':
                                                            '838B99',
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                    labelStyles={{
                                                        whiteSpace: 'pre-wrap',
                                                    }}
                                                />
                                            </div>
                                            <div className="rightcolumn">
                                                <InputField
                                                    isValid={isDescriptionValid}
                                                    lang={lang}
                                                    name="descripcion"
                                                    value={descripcion}
                                                    onChange={handleChange}
                                                    title={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_FORMULARIO_DESCRIPCION
                                                    }
                                                    type="textarea"
                                                    placeholder={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_FORMULARIO_DESCRIPCION_PLACEHOLDER
                                                    }
                                                />
                                                <InputField
                                                    ariaRequired
                                                    isValid={isCategoriesValid}
                                                    lang={lang}
                                                    name="categorias"
                                                    value={categorias}
                                                    onChange={handleChange}
                                                    required
                                                    title={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_FORMULARIO_DESCRIPCION
                                                    }
                                                    type="Categoria"
                                                    categoria={categories}
                                                />
                                                <InputField
                                                    isValid={isMasInfoValid}
                                                    lang={lang}
                                                    name="masInfo"
                                                    value={masInfo}
                                                    onChange={handleChange}
                                                    placeholder={
                                                        t.more_info[lang]
                                                    }
                                                    title={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_FORMULARIO_MAS_INFORMACION
                                                    }
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="send_box">
                                            {/* <ReCAPTCHA
                          sitekey="xqiopre"
                          onChange={onChange}
                        /> */}
                                            <div>
                                                <input
                                                    aria-label={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_SUBMIT_BUTTON
                                                    }
                                                    className="incidencias-reportBoton"
                                                    type="submit"
                                                    value={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_SUBMIT_BUTTON
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )
                    default:
                        return (
                            <div className="newIncidence">
                                <div className="newindicenciaDetail_mapContainer">
                                    {latitude && longitude && (
                                        <Map
                                            defaultCenter={{
                                                lat: latitude,
                                                lng: longitude,
                                            }}
                                            defaultZoom={zoom}
                                            mapId="new-incidence"
                                            onClick={handleClickLocation}
                                            onZoomChanged={handleChangeZoom}
                                            style={{
                                                height: '700px',
                                            }}
                                        >
                                            <AdvancedMarker
                                                position={{
                                                    lat: latitudeIncidence,
                                                    lng: longitudeIncidence,
                                                }}
                                            />
                                        </Map>
                                    )}
                                </div>
                                
                                <div className="new_incidenceindicenciaDetail-infobox"> 
                                    <div className="newinfobox__location_remember" id='location-warning'>
                                        <FontAwesomeIcon
                                            icon={faTriangleExclamation}
                                        />
                                        <p>{t.location_remember[lang]}</p>
                                    </div>
                                    <div className="incidenciaDetail-infobox_header">
                                        <h1 className="infobox_header-title">
                                            {
                                                translations.KALEZAINDU
                                                    .KALEZAINDU_NUEVA_INCIDENCIA
                                            }
                                        </h1>
                                        <div className="infobox_header-status">
                                            <div className="infobox_header-status_date">
                                                <CalendarioInput />
                                                <p>
                                                    {Moment(DateNow).format(
                                                        'D MMM YYYY'
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <form
                                        onSubmit={handleSubmit}
                                        className="form"
                                    >
                                        <div className="formulario_enviado formularioVisible">
                                            <CheckFormSended />
                                            <p>
                                                {
                                                    translations.KALEZAINDU
                                                        .KALEZAINDU_FORMULARIO_ENVIADO
                                                }
                                            </p>
                                        </div>
                                        <div className="formulario_no_enviado formularioNoEnviadoVisible">
                                            <CheckFormNoSended />
                                            <p>
                                                {
                                                    translations.KALEZAINDU
                                                        .KALEZAINDU_FORMULARIO_NO_ENVIADO
                                                }
                                            </p>
                                        </div>
                                        <div className="newinfobox_status-description">
                                            <div className="leftcolumn">
                                                <InputField
                                                    ariaRequired
                                                    isValid={isTitleValid}
                                                    lang={lang}
                                                    name="titulo"
                                                    value={titulo}
                                                    onChange={handleChange}
                                                    placeholder={t.title[lang]}
                                                    required
                                                    title={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_FORMULARIO_TITULO
                                                    }
                                                    type="text"
                                                />
                                                <ImageUploader
                                                    buttonText={
                                                        t.image_upload_title[
                                                            lang
                                                        ]
                                                    }
                                                    onChange={onDrop}
                                                    imgExtension={[
                                                        '.jpg',
                                                        '.gif',
                                                        '.png',
                                                        '.jpeg',
                                                    ]}
                                                    maxFileSize={10485760}
                                                    maxCount={6}
                                                    label={`${t.image_size[lang]}\n${t.image_quantity[lang]}\n${t.image_types[lang]}`}
                                                    singleImage={false}
                                                    withPreview={picturesSended}
                                                    withIcon={false}
                                                    buttonStyles={{
                                                        color: '#FFFFFF',
                                                        backgroundColor:
                                                            'var(--color-main)',
                                                        height: '100%',
                                                        width: '40%',
                                                        fontWeight: '400',
                                                        padding: '0.7em',
                                                    }}
                                                    labelStyles={{
                                                        whiteSpace: 'pre-wrap',
                                                    }}
                                                />
                                                <InputField
                                                    isValid={isEmailValid}
                                                    lang={lang}
                                                    name="email"
                                                    value={email}
                                                    onChange={handleChange}
                                                    placeholder={
                                                        t.email_example[lang]
                                                    }
                                                    title={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_FORMULARIO_EMAIL
                                                    }
                                                    type="email"
                                                />
                                            </div>
                                            <div className="rightcolumn">
                                                <InputField
                                                    isValid={isDescriptionValid}
                                                    lang={lang}
                                                    name="descripcion"
                                                    value={descripcion}
                                                    onChange={handleChange}
                                                    title={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_FORMULARIO_DESCRIPCION
                                                    }
                                                    type="textarea"
                                                    placeholder={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_FORMULARIO_DESCRIPCION_PLACEHOLDER
                                                    }
                                                />
                                                <InputField
                                                    ariaRequired
                                                    isValid={isCategoriesValid}
                                                    categoria={categories}
                                                    lang={incidencia.lang}
                                                    name="categorias"
                                                    onChange={handleChange}
                                                    required
                                                    title={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_FORMULARIO_CATEGORIA
                                                    }
                                                    type="Categoria"
                                                    value={categorias}
                                                />
                                                <InputField
                                                    isValid={isMasInfoValid}
                                                    lang={lang}
                                                    name="masInfo"
                                                    value={masInfo}
                                                    onChange={handleChange}
                                                    placeholder={
                                                        t.more_info[lang]
                                                    }
                                                    title={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_FORMULARIO_MAS_INFORMACION
                                                    }
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="send_box">
                                            {/* <ReCAPTCHA
                          sitekey="xqiopre"
                          onChange={onChange}
                        /> */}
                                            <div>
                                                <input
                                                    aria-label={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_SUBMIT_BUTTON
                                                    }
                                                    className="incidencias-reportBoton"
                                                    type="submit"
                                                    value={
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_SUBMIT_BUTTON
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )
                }
            })()}
        </PageRow>
    )
}

export default NewIncidence
